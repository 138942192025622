import { format, isAfter } from "date-fns";
import { useContext, useEffect, useRef, useState } from "react";
import { Alert } from "react-native";

import ALLERGY from "../../../../assets/icons/BASE/ALLERGY.svg";
import BLACKLISTED from "../../../../assets/icons/BASE/BLACKLISTED.svg";
import CASH from "../../../../assets/icons/BASE/CASH.svg";
import COMMENT from "../../../../assets/icons/BASE/COMMENT.svg";
import CREDIT_CARD from "../../../../assets/icons/BASE/CREDIT_CARD.svg";
import EVENT from "../../../../assets/icons/BASE/EVENT.svg";
import MESSAGE from "../../../../assets/icons/BASE/MESSAGE.svg";
import MORE_DOTS from "../../../../assets/icons/BASE/MORE_DOTS.svg";
import PHONE_OTP from "../../../../assets/icons/BASE/PHONE_OTP.svg";
import PREPAYMENT_BOOKING from "../../../../assets/icons/BASE/PREPAYMENT_BOOKING.svg";
import VIP from "../../../../assets/icons/BASE/VIP.svg";
import { ErrorInfoSuccessAlertModalContext } from "../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { BookingFragment } from "../../../graphql/generated/schema";
import {
  Booking_Status,
  Event_Type,
  Module_Listing_Client_Format,
  Payment_Type,
  useDuplicateBookingMutation,
  useRequestOtpForBookingMutation,
  useRequestPrepaidPaymentForBookingMutation,
  useUpdateBookingMutation,
} from "../../../graphql/generated/schema";
import type { THEME_COLORS } from "../../../theme";
import { ICON_SIZE } from "../../../theme";
import type { PALETTE_COLORS } from "../../../theme/Palette";
import { PALETTE } from "../../../theme/Palette";
import { displayModuleId, displayTables, getLabelsForEvent } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import BookingActionsModal from "../../BookingListDetails/BookingActionsModal/index";
import Touchable from "../../Touchable";
import BookingLIstCardClientFidelity from "../BookingLIstCardClientFidelity";
import BookingListCardOrigin from "../BookingListCardOrigin";
import BookingListCardStatusButton from "../BookingListCardStatusButton";
import { BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE } from "../BookingListCardStatusButtonItem";

interface BookingListCardProps {
  item: BookingFragment;
  nameFormat: Module_Listing_Client_Format;
  isDisabled?: boolean;
  isTablePressDisabled?: boolean;
  isStatusChangeDisabled?: boolean;
  isExtraActionDisabled?: boolean;
  onPress: (itemId: string) => void;
  onExtraActionPress: (itemId: string) => void;
  onDelete: (itemId: string) => void;
  onTablePress: (itemId: string) => void;
  onCompleteHandler?: (itemId: string) => void;
  onErrorHandler?: (itemId: string) => void;
  showClientComment?: boolean;
  showCreationDate?: boolean;
  showEventType?: boolean;
  showTables?: boolean;
  showAllergies?: boolean | null;
  showBasket?: boolean | null;
  dateFormat?: string;
  showActionButtons?: boolean;
}

const BookingListCard = ({
  item,
  onPress,
  onExtraActionPress,
  onDelete,
  nameFormat,
  isDisabled = false,
  isTablePressDisabled = false,
  isStatusChangeDisabled = false,
  isExtraActionDisabled = false,
  onTablePress,
  onCompleteHandler,
  onErrorHandler,
  showClientComment = true,
  showCreationDate = true,
  showEventType = true,
  showTables = true,
  dateFormat = "HH:mm",
  showActionButtons = true,
  showAllergies,
  showBasket,
}: BookingListCardProps) => {
  const [booking, setBooking] = useState(item);
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [cardContainer, setCardContainer] = useState({ width: 0, height: 0, x: 0, y: 0 });
  const {
    _id: itemId,
    createdAt,
    merchantClient: client,
    date,
    source,
    status,
    commentOwner,
    nbPersons,
    tables = [],
    comment = "",
    eventType,
    payment,
    prepaidPayment,
    prepaidPaymentRequested,
    otpRequested,
    otp,
    allergies,
    items,
  } = booking;

  const formattedCreatedDate = format(new Date(createdAt), "dd/MM/yyyy - HH:mm");
  const formattedDate = format(new Date(date), dateFormat);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const isTablePressed = useRef(false);

  const [updateBooking] = useUpdateBookingMutation();
  const [duplicateBooking] = useDuplicateBookingMutation();
  const [requestOtp] = useRequestOtpForBookingMutation();
  const [requestPrepaidPayment] = useRequestPrepaidPaymentForBookingMutation();

  useEffect(() => {
    setBooking(item);
  }, [item]);

  const ICON_VIEW_TYPE =
    cardContainer.width >= 600
      ? BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.TEXT
      : BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.ICON;

  const IS_ALLOWED_TO_SEAT_PERSONS =
    status !== Booking_Status.CancelledByOwner &&
    status !== Booking_Status.CancelledByUser &&
    status !== Booking_Status.Noshow;

  const displayClientName = () => {
    if (!client) return "PASSAGE";
    if (nameFormat === Module_Listing_Client_Format.LastFirstName) {
      const formattedClientName = `${client.lastName} ${client.firstName}`;
      return formattedClientName;
    } else if (nameFormat === Module_Listing_Client_Format.FirstLastName) {
      return `${client.firstName} ${client.lastName}`;
    } else if (nameFormat === Module_Listing_Client_Format.LastName) {
      return `${client.lastName}`;
    } else {
      return `${client.firstName}`;
    }
  };

  const handleTablePress = (tableId: string) => {
    onTablePress(tableId);

    isTablePressed.current = false;
  };

  const handleCardPress = () => {
    if (isStatusModalOpen) return;

    onPress(itemId);
  };

  const handleRequstOtp = async () => {
    try {
      const { data } = await requestOtp({
        variables: {
          bookingId: itemId,
        },
      });

      if (data?.requestOtpForBooking) {
        setBooking(data?.requestOtpForBooking);
      }
    } catch (err) {
      Alert.alert("Erreur", "Une erreur est survenue" + err.message || "");

      // infoAlert?.openAlert(
      //   "Erreur",
      //   [
      //     {
      //       code: err?.code || "error",
      //       message: err?.message || "Une erreur est survenue",
      //       path: err?.message || "Une erreur est survenue",
      //     },
      //   ],
      //   "error",
      // );
    }
  };

  const handleRequstPrepaidPayment = async () => {
    try {
      const { data } = await requestPrepaidPayment({
        variables: {
          bookingId: itemId,
        },
      });

      if (data?.requestPrepaidPaymentForBooking) {
        setBooking(data?.requestPrepaidPaymentForBooking);
      }
    } catch (err) {
      console.log("err", JSON.stringify(err, null, 2));

      Alert.alert("Erreur", "Une erreur est survenue " + err.message || "");

      // infoAlert?.openAlert(
      //   "Erreur",
      //   [
      //     {
      //       code: err?.code || "error",
      //       message: err?.message || "Une erreur est survenue",
      //       path: err?.message || "Une erreur est survenue",
      //     },
      //   ],
      //   "error",
      // );
    }
  };

  const handleDuplicateConfirm = async (dates: Date[]) => {
    try {
      setIsModalOpen(false);

      await duplicateBooking({
        variables: {
          bookingId: itemId,
          duplication: {
            dates,
          },
        },
      });
    } catch (err) {
      console.log("err", JSON.stringify(err, null, 2));

      Alert.alert("Erreur", "Une erreur est survenue " + err.message || "");

      // infoAlert?.openAlert(
      //   "Erreur",
      //   [
      //     {
      //       code: err?.code || "error",
      //       message: err?.message || "Une erreur est survenue",
      //       path: err?.message || "Une erreur est survenue",
      //     },
      //   ],
      //   "error",
      // );
    }
  };

  const handleBookingStatusChange = async (
    newStatus: Booking_Status,
    refusalReason: string,
    applyAutomaticPrepaymentPercentage: boolean,
  ) => {
    try {
      const { data } = await updateBooking({
        variables: {
          bookingId: itemId,
          booking: {
            status: newStatus,
            refusalReason,
            applyAutomaticPrepaymentPercentage,
            sendClientNotifications: true,
          },
        },
      });

      if (data?.updateBookingForPro) {
        setBooking(data?.updateBookingForPro);
        onCompleteHandler?.(itemId);
      }
    } catch (err) {
      onErrorHandler?.(itemId);
      Alert.alert("Erreur", "Une erreur est survenue " + err?.message || "");

      // infoAlert.openAlert(
      //   "Erreur",
      //   [
      //     {
      //       code: "error",
      //       message: err.message || "Une erreur est survenue",
      //       path: err.message || "Une erreur est survenue",
      //     },
      //   ],
      //   "error",
      // );

      console.log("err", JSON.stringify(err, null, 2));
    }
  };

  const handleOnSubmit = async (key: string) => {
    try {
      if (key === "DELETE") {
        onDelete(booking._id);
        // await handleDeleteBooking();
      } else if (key === "DUPLICATE") {
        // await setIsDuplicateCalendarModalOpen(true);
      } else if (key === "MODIFY") {
        // await handleModifyValidateButton();
      } else if (key === "PREPAID_REQUEST") {
        await handleRequstPrepaidPayment();
      } else if (key === "OTP_REQUEST") {
        await handleRequstOtp();
      }
    } catch (err) {
      console.log("err", err);
    }
    isTablePressed.current = false;
  };

  const displayExtraActions = () => {
    return (
      <BookingActionsModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          isTablePressed.current = false;
        }}
        booking={booking}
        handleSubmit={handleOnSubmit}
        onDuplicateConfirm={handleDuplicateConfirm}
      />
    );
  };

  const determinePaymentStatus = (): THEME_COLORS => {
    const paid = booking?.payment?.paid;

    const IS_CANCELLED =
      status === Booking_Status.Noshow ||
      status === Booking_Status.CancelledByOwner ||
      status === Booking_Status.CancelledByUser;

    const IS_REFUNDED = status === Booking_Status.Refunded;

    if (IS_CANCELLED || IS_REFUNDED) return "redBackground";

    return paid ? "greenBackground" : "orangeBackground";
  };

  const determineIconColor = (): PALETTE_COLORS => {
    const IS_CANCELLED =
      status === Booking_Status.Noshow ||
      status === Booking_Status.CancelledByOwner ||
      status === Booking_Status.CancelledByUser;

    const IS_REFUNDED = status === Booking_Status.Refunded;

    if (IS_CANCELLED || IS_REFUNDED) return "red";

    return booking?.payment?.paid ? "green" : "orange";
  };

  const determinePaymentType = () => {
    const color = determineIconColor();

    switch (booking?.payment?.paymentType) {
      case Payment_Type.Cash:
        return <CASH width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE[color]} />;
      case Payment_Type.Card:
        return <CREDIT_CARD width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE[color]} />;
      case Payment_Type.None:
        return <CASH width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE[color]} />;

      default:
        break;
    }
  };

  const HAS_ITEMS =
    booking?.items?.menus?.length > 0 || booking?.items?.products?.length > 0;

  const displayItemsPayment = () => {
    if (!HAS_ITEMS) return <></>;

    return (
      <Box backgroundColor={determinePaymentStatus()} p="s" borderRadius="icon">
        {determinePaymentType()}
      </Box>
    );
  };

  const determineBookingPrepaidPaymentBackgroundColor = () => {
    const paid = booking?.prepaidPayment?.paid;

    const IS_CANCELLED =
      status === Booking_Status.Noshow ||
      status === Booking_Status.CancelledByOwner ||
      status === Booking_Status.CancelledByUser;

    const IS_REFUNDED = status === Booking_Status.Refunded;

    if (IS_CANCELLED || IS_REFUNDED) return "redBackground";

    return paid ? "greenBackground" : "orangeBackground";
  };

  const determinePrepaidPaymentIconColor = (): PALETTE_COLORS => {
    const IS_CANCELLED =
      status === Booking_Status.Noshow ||
      status === Booking_Status.CancelledByOwner ||
      status === Booking_Status.CancelledByUser;

    const IS_REFUNDED = status === Booking_Status.Refunded;

    if (IS_CANCELLED || IS_REFUNDED) return "red";

    return booking?.prepaidPayment?.paid ? "green" : "orange";
  };

  const displayOtp = () => {
    if (!otpRequested && !otp) return <></>;

    let color = PALETTE.orange;

    if (otp?.verified) {
      color = PALETTE.green;
    } else if (isAfter(new Date(), new Date(otp?.expiresAt))) {
      color = PALETTE.red;
    }

    return (
      <Box>
        <PHONE_OTP width={ICON_SIZE} height={ICON_SIZE} fill={color} />
      </Box>
    );
  };

  const displayBookingPrepayment = () => {
    const color = determinePrepaidPaymentIconColor();

    return (
      <Box
        backgroundColor={determineBookingPrepaidPaymentBackgroundColor()}
        p="s"
        borderRadius="icon"
      >
        <PREPAYMENT_BOOKING width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE[color]} />
      </Box>
    );
  };

  const displayBookingPrepaidOrItemsPayment = () => {
    if (booking?.prepaidPayment || prepaidPaymentRequested) {
      return displayBookingPrepayment();
    }

    if (HAS_ITEMS) {
      return displayItemsPayment();
    }
  };

  if (booking.isDeleted) return <></>;

  const shouldShowTables = showTables && (!booking?.floor || tables?.length > 0);
  const shouldFloorSelection = booking?.floor && tables?.length === 0;

  return (
    <Box
      flex={1}
      onLayout={e => setCardContainer(e.nativeEvent.layout)}
      flexDirection="row"
      alignItems="center"
    >
      <Touchable
        containerStyle={{ flex: 1 }}
        disabled={isDisabled}
        style={{ flex: 1 }}
        onPress={handleCardPress}
      >
        <Box flex={1}>
          <Box flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box flex={0.4}>
              <CustomText variant="label" numberOfLines={1}>
                {displayClientName()}
              </CustomText>
            </Box>
            <Box
              flex={0.6}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="label" pr="xs">
                x{nbPersons}
              </CustomText>
              <CustomText variant="content">{formattedDate}</CustomText>

              {shouldShowTables && (
                <Touchable
                  disabled={
                    isTablePressDisabled || !onTablePress || !IS_ALLOWED_TO_SEAT_PERSONS
                  }
                  onPressIn={() => {
                    isTablePressed.current = true;
                  }}
                  onPress={() => {
                    handleTablePress(itemId);
                  }}
                  style={{
                    minHeight: 30,
                    minWidth: 50,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  hitSlop={{
                    top: 20,
                    bottom: 20,
                    left: 20,
                    right: 20,
                  }}
                >
                  <CustomText variant="content" color="primaryTextColor">
                    {tables?.length > 0 ? displayTables(tables || []) : "-"}
                  </CustomText>
                </Touchable>
              )}

              {shouldFloorSelection && (
                <Touchable
                  disabled={
                    isTablePressDisabled || !onTablePress || !IS_ALLOWED_TO_SEAT_PERSONS
                  }
                  onPressIn={() => {
                    isTablePressed.current = true;
                  }}
                  onPress={() => {
                    handleTablePress(itemId);
                  }}
                  style={{
                    minHeight: 30,
                    minWidth: 50,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  hitSlop={{
                    top: 20,
                    bottom: 20,
                    left: 20,
                    right: 20,
                  }}
                >
                  <CustomText variant="content" color="primaryTextColor">
                    {booking?.floor?.prefix}
                  </CustomText>
                </Touchable>
              )}

              <BookingListCardStatusButton
                status={status}
                type={ICON_VIEW_TYPE}
                onPress={handleBookingStatusChange}
                isDisabled={isStatusChangeDisabled}
                prepaidPayment={prepaidPayment}
                payment={payment}
                setIsOpen={setIsStatusModalOpen}
              />
            </Box>
            {!isExtraActionDisabled && showActionButtons && (
              <Touchable onPress={() => setIsModalOpen(true)}>
                <MORE_DOTS width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.black} />
              </Touchable>
            )}
          </Box>
          <Box flexDirection="row" alignItems="center">
            {typeof client?.bookingOccurences === "number" && (
              <Box mr="m">
                <BookingLIstCardClientFidelity occurences={client?.bookingOccurences} />
              </Box>
            )}

            {source && (
              <Box mr="s">
                <BookingListCardOrigin origin={source} />
              </Box>
            )}

            {comment && (
              <Box mr="s">
                <COMMENT width={15} height={15} fill={PALETTE.darkBlue} />
              </Box>
            )}

            {commentOwner && (
              <Box mr="s">
                <MESSAGE fill={PALETTE.darkBlue} />
              </Box>
            )}
            {eventType && eventType !== Event_Type.None && (
              <Box mr="s">
                <EVENT fill={PALETTE.darkBlue} />
              </Box>
            )}
            {allergies && (
              <Box mr="s">
                <ALLERGY fill={PALETTE.darkBlue} />
              </Box>
            )}
            {client?.isVIP && (
              <Box mr="s">
                <VIP width={15} height={15} fill={PALETTE.green} />
              </Box>
            )}
            {client?.isBlacklisted && (
              <Box mr="s">
                <BLACKLISTED fill={PALETTE.red} />
              </Box>
            )}

            {displayBookingPrepaidOrItemsPayment()}

            {displayOtp()}
          </Box>

          {showClientComment && comment && (
            <Box flexDirection="row" alignItems="center" mt="xs">
              <CustomText variant="content" color="lightGrey">
                Commentaire:
                <CustomText
                  numberOfLines={1}
                  ellipsizeMode="clip"
                  variant="text"
                  color="lightGrey"
                >
                  {" "}
                  {comment}
                </CustomText>
              </CustomText>
            </Box>
          )}

          {commentOwner && (
            <Box flexDirection="row" alignItems="center" mt="xs">
              <CustomText variant="content" color="lightGrey">
                Note:
                <CustomText numberOfLines={1} variant="text" color="lightGrey">
                  {" "}
                  {commentOwner}
                </CustomText>
              </CustomText>
            </Box>
          )}

          {showEventType && eventType && eventType !== Event_Type.None && (
            <Box flexDirection="row" alignItems="center" mt="xs">
              <CustomText variant="content" color="lightGrey">
                Évènement:{" "}
                <CustomText variant="text" color="lightGrey">
                  {getLabelsForEvent(eventType, eventType)}
                </CustomText>
              </CustomText>
            </Box>
          )}

          {allergies && showAllergies && (
            <Box flexDirection="row" alignItems="center" mt="xs">
              <CustomText variant="content" color="lightGrey">
                Allergies:{" "}
                <CustomText variant="text" color="lightGrey" numberOfLines={1}>
                  {allergies}
                </CustomText>
              </CustomText>
            </Box>
          )}

          {showBasket && HAS_ITEMS && (
            <Box flexDirection="column" mt="xs">
              <CustomText variant="content" color="lightGrey">
                Détails produits:{" "}
              </CustomText>
              <Box pl="s">
                {items?.menus?.map(menu => {
                  return (
                    <CustomText key={menu.name} variant="text" color="lightGrey">
                      {menu.quantity}x {menu.name}
                    </CustomText>
                  );
                })}
                {items?.products?.map(product => {
                  return (
                    <CustomText key={product.name} variant="text" color="lightGrey">
                      {product.quantity}x {product.name}
                    </CustomText>
                  );
                })}
              </Box>
            </Box>
          )}

          {showCreationDate && (
            <Box flexDirection="row" alignItems="center" mt="xs">
              <CustomText variant="text" color="lightGrey">
                Réservation crée le {formattedCreatedDate} - {displayModuleId(itemId)}
              </CustomText>
            </Box>
          )}
        </Box>
      </Touchable>

      {displayExtraActions()}
    </Box>
  );
};

export default BookingListCard;
