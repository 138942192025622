import type { StripeCardElement } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import config from "../../config/env";
import type { MerchantSettingsBillingDetails } from "../../graphql/generated/schema";

const { STRIPE_PUBLISHABLE_KEY } = config;

export const handleCreateSepaPaymentMethod = async (
  clientSecret: string,
  iban: string,
  billingDetails: MerchantSettingsBillingDetails,
) => {
  try {
    const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);

    if (!stripe) throw new Error("STRIPE_NOT_LOADED");

    const { address, name, email } = billingDetails;

    const result = await stripe.confirmSepaDebitSetup(clientSecret, {
      payment_method: {
        sepa_debit: {
          iban,
        },
        billing_details: {
          address: {
            city: address.city,
            country: address.country,
            line1: address.street,
            postal_code: address.zipCode,
          },
          name,
          email,
        },
      },
    });

    // const result = await stripe.createPaymentMethod({
    //   type: "sepa_debit",
    //   sepa_debit: {
    //     iban,
    //   },
    //   billing_details: {
    //     address: {
    //       city: address.city,
    //       country: address.country,
    //       line1: address.street,
    //       postal_code: address.zipCode,
    //     },
    //     name,
    //     email,
    //   },
    // });

    return result;
  } catch (err) {
    console.log("err create payment method", err);
  }
};

export const handleCreatePaymentMethod = async (card: StripeCardElement) => {
  try {
    const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);

    if (!stripe) throw new Error("STRIPE_NOT_LOADED");

    const result = await stripe.createPaymentMethod({
      card,
      type: "card",
    });

    return result;
  } catch (err) {
    console.log("err create payment method", err);
  }
};

export const handleConfirmPayment = async (pmSecret: string, pmId: string) => {
  const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);

  if (!stripe) throw new Error("STRIPE_NOT_LOADED");

  const { paymentIntent, error } = await stripe.confirmCardPayment(pmSecret, {
    payment_method: pmId,
  });

  return {
    paymentIntent,
    error,
  };
};

export const handleApplePay = async (pmSecret: string) => {
  return;
};

export const handleGooglePay = async (pmSecret: string, currency: string) => {
  return;
};

export const isAppleAvailable = async () => {
  return false;
};

export const isGoogleAvailable = async () => {
  return false;
};
