import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import { Platform } from "react-native";

import { PALETTE } from "../theme/Palette";

export const getDeviceInfo = () => {
  if (Device.isDevice) {
    return {
      deviceName: Device.deviceName,
      modelName: Device.modelName,
      brand: Device.brand,
      osName: Device.osName,
      osVersion: Device.osVersion,
      osBuildId: Device.osBuildId,
      osInternalBuildId: Device.osInternalBuildId,
      osBuildFingerprint: Device.osBuildFingerprint,
      platformApiLevel: Device.platformApiLevel,
      deviceYearClass: Device.deviceYearClass,
      totalMemory: Device.totalMemory,
      supportedCpuArchitectures: Device.supportedCpuArchitectures,
      manufacturer: Device.manufacturer,
      deviceType: Device.DeviceType,
      isDevice: Device.isDevice,
    };
  }
};

export async function registerForPushNotificationsAsync() {
  try {
    let token;

    if (Platform.OS === "web") return token;

    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        return;
      }

      token = (await Notifications.getExpoPushTokenAsync()).data;

      if (Platform.OS === "android") {
        Notifications.setNotificationChannelAsync("default", {
          name: "default",
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: PALETTE.darkBlue,
        });
      }
    }

    return token;
  } catch (err) {
    console.log("ERROR_REGISTERING_FOR_PUSH_NOTIFICATIONS", err);
  }
}

export enum STORAGE_KEYS {
  API_TOKEN = "@eiwie-apiToken",
  LICENCE_KEY = "@eiwie-licenceKey",
  DEVICE_ID = "@eiwie-deviceId",
  IS_OWNER = "@eiwie-isOwner",
  STAFF_ID = "@eiwie-staffId",
}

export const storeToStorage = async (key: STORAGE_KEYS, apiToken: string) => {
  try {
    await AsyncStorage.setItem(key, apiToken);
  } catch (e) {
    console.log("err saving to storage", e);
    // saving error
  }
};

export const getFromStorage = async (key: STORAGE_KEYS) => {
  try {
    const value = await AsyncStorage.getItem(key);
    if (value !== null) {
      return value;
      // value previously stored
    }
  } catch (e) {
    console.log("err getting from storage", e);
    // error reading value
  }
};

export const removeFromStorage = async (key: STORAGE_KEYS) => {
  try {
    await AsyncStorage.removeItem(key);
  } catch (e) {
    console.log("err removing from storage", e);
    // remove error
  }
};
