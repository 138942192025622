/* eslint-disable @typescript-eslint/no-explicit-any */
import type { RouteProp } from "@react-navigation/native";
import { useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { format, isBefore, isSameDay, isValid, setHours, setMinutes } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";

import CANCEL from "../../../../assets/icons/BASE/CANCEL.svg";
import VALIDATE from "../../../../assets/icons/BASE/VALIDATE.svg";
import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import { CustomButton } from "../../../components/Button";
import type { ListingTab } from "../../../components/ListingTabs";
import { ListingTabs } from "../../../components/ListingTabs";
import ClientSelection from "../../../components/NewBooking/ClientSelection";
import NewBookingDateSelection from "../../../components/NewBooking/DateSelection";
import NewBookingAdvancedSelection from "../../../components/NewBooking/NewBookingAdvancedSelection";
import NewBookingPersonsSelection from "../../../components/NewBooking/PersonsSelection";
import NewBookingTimeSelection from "../../../components/NewBooking/TimeSelection";
import ScreenHeader from "../../../components/ScreenHeader";
import SplitView from "../../../components/SplitView";
import ToggleInputLine from "../../../components/ToggleInputLine";
import { AppContext } from "../../../contexts/AppContext";
import { ErrorInfoSuccessAlertModalContext } from "../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  BookingSettingsFragment,
  CreateBookingProInput,
  MerchantClientFragment,
  MerchantClientInput,
  PopCallFragment,
  ProductFragment,
  SelectedBookingTableFragment,
} from "../../../graphql/generated/schema";
import {
  Booking_Form_Input,
  Event_Type,
  Origin,
  Sex,
  useCreateBookingMutation,
  useGetBookingAvailabilitiesWithBookingInfoLazyQuery,
  useGetBookingSettingsLazyQuery,
  useGetMerchantClientLazyQuery,
  useUpdatePopCallMutation,
} from "../../../graphql/generated/schema";
import type { BookingStackParamList } from "../../../navigation/AppStack/BookingStack/ParamList";
import { captureAndReportErrors } from "../../../sentry";
import { PALETTE } from "../../../theme/Palette";
import { BOOKING_STATUS } from "../../../types";
import type { ERROR } from "../../../utils/common";
import {
  createDateWithTime,
  formaYupErrors,
  isPhoneNumberValid,
  removeTypeNames,
} from "../../../utils/common";

type NewBookingScreenRouteProp = RouteProp<BookingStackParamList, "NEW_BOOKING">;

const TABS: ListingTab[] = [
  {
    title: "Date",
    value: "",
    key: "DATE",
    color: "primaryTextColor",
  },
  {
    title: "Pax",
    value: "",
    key: "PERSONS",
    color: "lightGrey",
  },
  {
    title: "Horaire",
    value: "",
    key: "TIME",
    color: "lightGrey",
  },
  {
    title: "Avancé",
    value: "",
    key: "ADVANCED",
    color: "lightGrey",
  },
  {
    title: "Client",
    value: "",
    key: "CLIENT",
    color: "lightGrey",
  },
];

const DEFAULT_CLIENT: MerchantClientInput = {
  sex: Sex.NotSpecified,
  firstName: "",
  lastName: "",
  email: "",
  secondaryEmail: "",
  phone: "",
  secondaryPhone: "",
  isVIP: false,
  comment: "",
  language: "fr",
  acceptedReceiveNewsLetters: false,
  acceptedReceiveMarketingSms: false,
  isBlacklisted: false,
  picture: "",
  merchantCompanyId: "",
  dateOfBirth: null,
};

const DEFAULT_BOOKING: CreateBookingProInput = {
  client: DEFAULT_CLIENT,
  commentOwner: "",
  date: new Date(),
  nbPersons: 0,
  serviceId: "",
  slotId: "",
  source: Origin.Local,
  eventType: Event_Type.None,
  status: BOOKING_STATUS.VALIDATED,
  tables: [],
  isNotDeductedFromStock: false,
  includePrepaid: false,
  sendClientNotifications: false,
};

const NewBooking = () => {
  // useResizeMode();
  const {
    params: { selectedDate = null, phone = "", merchantClientId = "", tables = [] } = {},
  } = useRoute<NewBookingScreenRouteProp>();
  const infoAlertContext = useContext(ErrorInfoSuccessAlertModalContext);
  const [listTabs, setListTabs] = useState(TABS);
  const [time, setTime] = useState<string>("");
  const navigation = useNavigation();
  const [booking, setBooking] = useState({ ...DEFAULT_BOOKING, tables });
  const [isOnMainClientPage, setIsOnMainClientPage] = useState(true);
  const [isAdvancedTabFilled, setIsAdvancedTabFilled] = useState(false);
  const appContext = useContext(AppContext);
  const [bookingDate, setBookingDate] = useState<Date | null>(selectedDate);
  const [bookingSettings, setBookingSettings] = useState<
    BookingSettingsFragment | undefined
  >();
  const [currentTab, setCurrentTab] = useState(selectedDate ? TABS[1].key : TABS[0].key);
  const [isLoadingBookingAvailabilities, setIsLoadingBookingAvailabilities] =
    useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [bookingServices, setBookingServices] = useState([]);

  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [errors, setErrors] = useState<ERROR[]>([]);
  const [call, setCall] = useState<PopCallFragment | undefined>();

  const [createBooking] = useCreateBookingMutation();
  const [getBookingAvailabilitiesForDate] =
    useGetBookingAvailabilitiesWithBookingInfoLazyQuery();
  const [updateCall] = useUpdatePopCallMutation();
  const [getMerchantClient] = useGetMerchantClientLazyQuery();
  const [getBookingSettings] = useGetBookingSettingsLazyQuery();

  const isFieldRequired = (field: Booking_Form_Input) => {
    const bookingForm = bookingSettings?.bookingForm;

    if (!bookingForm || !bookingForm.inputs) return false;

    return bookingForm?.inputs?.some(
      input => input.inputName === field && input.isRequired,
    );
  };

  const validateForm = async (displayErrors = true) => {
    try {
      const IS_SEX_REQUIRED = isFieldRequired(Booking_Form_Input.Civility);
      const IS_FIRST_NAME_REQUIRED = isFieldRequired(Booking_Form_Input.FirstName);
      const IS_EMAIL_REQUIRED = isFieldRequired(Booking_Form_Input.Email);
      const IS_ADDRESS_REQUIRED = isFieldRequired(Booking_Form_Input.Address);
      const IS_CLIENT_COMMENT_REQUIRED = isFieldRequired(
        Booking_Form_Input.MerchantClientNote,
      );
      const IS_VIP_REQUIRED = isFieldRequired(Booking_Form_Input.Vip);
      const IS_BOOKING_NOTE_REQUIRED = isFieldRequired(Booking_Form_Input.CommentOwner);
      const IS_EVENT_REQUIRED = isFieldRequired(Booking_Form_Input.EventType);
      const IS_SOURCE_REQUIRED = isFieldRequired(Booking_Form_Input.Origin);
      const IS_SECONDARY_EMAIL_REQUIRED = isFieldRequired(
        Booking_Form_Input.SecondaryEmail,
      );
      const IS_SECONDARY_PHONE_REQUIRED = isFieldRequired(
        Booking_Form_Input.SecondaryPhone,
      );
      const IS_BIRTHDAY_REQUIRED = isFieldRequired(Booking_Form_Input.Birthday);
      const IS_COMPANY_REQUIRED = isFieldRequired(Booking_Form_Input.Company);

      const schema = yup.object().shape({
        source: IS_SOURCE_REQUIRED
          ? yup.string().required("Une source pour la réservation est requise")
          : yup.string(),
        date: yup.date().required("Une date pour la réservation est requise"),
        nbPersons: yup.number().min(1),
        status: yup.number().required(),
        commentOwner: IS_BOOKING_NOTE_REQUIRED
          ? yup.string().required("Un commentaire pour la réservation est requis")
          : yup.string(),
        eventType: IS_EVENT_REQUIRED
          ? yup.string().required("Un type d'évenement est requis")
          : yup.string(),
        client: yup.object().shape({
          sex: IS_SEX_REQUIRED
            ? yup
                .string()
                .notOneOf([Sex.NotSpecified], "Un sexe pour le client est requis")
                .required("Un sexe pour le client est requis")
            : yup.string(),
          firstName: IS_FIRST_NAME_REQUIRED
            ? yup.string().required("Un Prénom pour le client est requis")
            : yup.string(),
          lastName: yup.string().required("Un Nom pour le client est requis"),
          email: IS_EMAIL_REQUIRED
            ? yup.string().email().required("Un email pour le client est requis")
            : yup.string().email(),
          address: yup
            .mixed()
            .test("is-address-valid", "L'addresse du client est invalide", value => {
              if (!value && !IS_ADDRESS_REQUIRED) return true;

              if (!value || typeof value !== "object") return false;

              const { number, street, city, zipCode, country } = value;

              return number && street && city && zipCode && country;
            }),
          // : // .shape({
          //   number: yup.string().required("Le numéro de l'addresse est requis"),
          //   street: yup.string().required("La rue de l'addresse est requise"),
          //   city: yup.string().required("La ville de l'addresse est requise"),
          //   zipCode: yup
          //     .string()
          //     .required("Le code postal de l'addresse est requis"),
          //   country: yup.string().required("Le pays de l'addresse est requis"),
          // })
          // .required("L'addresse du client est requise")
          // yup
          //   .object()
          //   .shape({
          //     number: yup.string(),
          //     street: yup.string(),
          //     city: yup.string(),
          //     zipCode: yup.string(),
          //     country: yup.string(),
          //   })
          //   .nullable(),

          isVIP: IS_VIP_REQUIRED ? yup.boolean().required() : yup.boolean(),
          comment: IS_CLIENT_COMMENT_REQUIRED
            ? yup.string().required("Un commentaire pour le client est requis")
            : yup.string(),

          phone: yup
            .string()
            .required("Veuillez rentrez un numéro valide")
            .test(
              "is-phone-number-valid",
              "Un numéro de téléphone valide pour le client est requis",
              val => {
                if (!val) return false;

                return isPhoneNumberValid(val || "");
              },
            ),
          secondaryEmail: IS_SECONDARY_EMAIL_REQUIRED
            ? yup
                .string()
                .email()
                .required("Un email secondaire pour le client est requis")
            : yup.string().email().optional(),
          secondaryPhone: yup
            .string()
            .test(
              "is-phone-number-valid",
              "Un numéro de téléphone secondaire pour le client est requis",
              val => {
                if (!val || !IS_SECONDARY_PHONE_REQUIRED) return true;

                return isPhoneNumberValid(val || "");
              },
            ),

          dateOfBirth: yup
            .mixed()
            .nullable()
            .test(
              "is-date-of-birth-valid",
              "Une date de naissance valide est requise",
              value => {
                if (!value && !IS_BIRTHDAY_REQUIRED) return true;

                if (!value || !isValid(new Date(value))) return false;

                return true;
              },
            ),
        }),
      });

      await schema.validate(booking, { abortEarly: false });

      return true;
    } catch (err) {
      console.log("err", err);
      setErrors(formaYupErrors(err));

      if (displayErrors)
        infoAlertContext.openAlert("Erreur", formaYupErrors(err), "error");
    }
    return false;
  };

  const handleGetBookingSettings = async () => {
    try {
      const { data: response } = await getBookingSettings({
        fetchPolicy: "cache-and-network",
      });

      if (response?.getBookingSettings) {
        setBookingSettings(response.getBookingSettings);
      }
    } catch (err) {
      console.log("ERR_GET_BOOKING_SETTINGS", err);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetBookingSettings();
    }, []),
  );

  const handleSetClientInfoFromCall = async () => {
    if (merchantClientId) {
      const { data: client } = await getMerchantClient({
        variables: {
          merchantClientId,
        },
      });

      if (client?.getMerchantClient) {
        setBooking({
          ...booking,
          clientId: merchantClientId,
          client: {
            ...booking.client,
            ...client.getMerchantClient,
          },
        });
      }
    } else if (phone) {
      setBooking(prev => ({
        ...prev,
        client: {
          ...prev.client,
          phone,
        },
      }));
    }
  };

  useEffect(() => {
    handleSetClientInfoFromCall();
  }, []);

  const handleGetBookingAvailabilitiesForDate = async (date: Date) => {
    try {
      setIsLoadingBookingAvailabilities(true);

      const { data: getBookingAvailabilitiesWithBookingInfoData } =
        await getBookingAvailabilitiesForDate({
          variables: {
            date,
          },
          fetchPolicy: "cache-and-network",
        });

      const services =
        getBookingAvailabilitiesWithBookingInfoData?.getBookingAvailabilitiesWithBookingInfo ||
        [];

      const isCurrentDay =
        isValid(new Date(date)) && isSameDay(new Date(), new Date(date));

      const now = new Date().getTime();
      const sortedServices = services
        .filter(s => s.serviceId !== "HS_SERVICE")
        .sort((a, b) => {
          const aToDate = createDateWithTime(new Date(), a.startTime);
          const bToDate = createDateWithTime(new Date(), b.startTime);
          if (isCurrentDay) {
            return Math.abs(aToDate.getTime() - now) - Math.abs(bToDate.getTime() - now);
          }
          return isBefore(aToDate, bToDate) ? -1 : 1;
        });

      setBookingServices(sortedServices);

      if (sortedServices.length > 0) {
        const serviceId = sortedServices[0]?.serviceId || "";

        // if (booking.serviceId) return;

        setBooking(prev => ({
          ...prev,
          serviceId,
        }));
      }
    } catch (err) {
      console.log("err getBookingAvailabilitiesForDate for new booking", err);

      captureAndReportErrors(err);
    } finally {
      setIsLoadingBookingAvailabilities(false);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      handleGetBookingAvailabilitiesForDate(selectedDate);
    }
  }, [selectedDate]);

  const handleUpdateCall = () => {
    try {
      if (!call) return;

      updateCall({
        variables: {
          popCallId: call._id,
          popCall: {
            acknowledged: true,
          },
        },
      });
    } catch (err) {
      captureAndReportErrors(err);

      console.log("err updating call", err);
    }
  };

  const handleNewBooking = async () => {
    const isFormValid = await validateForm();

    if (!isFormValid || !bookingDate) return;

    setIsInProgress(true);

    try {
      const { client } = booking;

      const formattedClient = removeTypeNames({
        ...client,
        dateOfBirth: client?.dateOfBirth
          ? new Date(client?.dateOfBirth)?.toISOString()
          : null,
      });
      delete formattedClient._id;

      const formattedBookingDate = createDateWithTime(bookingDate, time);

      const consolidatedBooking: CreateBookingProInput = {
        ...booking,
        date: formattedBookingDate,
        client: formattedClient,
      };

      delete consolidatedBooking?.client?.bookingOccurences;
      delete consolidatedBooking?.client?.orderOccurences;
      delete consolidatedBooking?.client?.ticketOccurences;
      delete consolidatedBooking?.client?.generatedProfit;
      delete consolidatedBooking?.client?.averageProfit;
      delete consolidatedBooking?.client?.averageRating;

      await createBooking({
        variables: {
          booking: consolidatedBooking,
        },
      });

      await handleUpdateCall();

      navigation.goBack();
    } catch (err: any) {
      captureAndReportErrors(err);

      console.log("err submit", err, JSON.stringify(err, null, 2));

      infoAlertContext.openAlert(
        "Une erreur est survenue lors de la création de la réservation",
        [{ path: "submit", code: "submit", message: err.message }],
        "error",
      );

      setErrors([{ path: "submit", code: "submit", message: err.message }]);
    } finally {
      setIsInProgress(false);
    }
  };

  const marked = !bookingDate
    ? {
        [format(new Date(), "yyyy-MM-dd")]: {
          // selected: true,
          marked: true,
          // selectedColor: PALETTE.green,
        },
      }
    : {
        [format(bookingDate, "yyyy-MM-dd")]: {
          selected: true,
          marked: true,
          selectedColor: PALETTE.green,
        },
      };

  const getListTabs = () => {
    const newTabs = listTabs.map(tab => {
      if (tab.key === "DATE") {
        return {
          ...tab,
          value: bookingDate ? format(bookingDate, "d MMM") : "",
          color: bookingDate || currentTab === "DATE" ? "primaryTextColor" : "lightGrey",
        };
      }
      if (tab.key === "PERSONS") {
        return {
          ...tab,
          value: booking.nbPersons >= 1 ? `${booking.nbPersons}px` : "",
          color:
            booking.nbPersons >= 1 || currentTab === "PERSONS"
              ? "primaryTextColor"
              : "lightGrey",
        };
      }
      if (tab.key === "TIME") {
        return {
          ...tab,
          value: time || "",
          color: time || currentTab === "TIME" ? "primaryTextColor" : "lightGrey",
        };
      }

      if (tab.key === "ADVANCED") {
        return {
          ...tab,
          value: isAdvancedTabFilled ? "Oui" : "",
          color:
            isAdvancedTabFilled || currentTab === "ADVANCED"
              ? "primaryTextColor"
              : "lightGrey",
        };
      }
      if (tab.key === "CLIENT") {
        return {
          ...tab,
          value: "",
          color: currentTab === "CLIENT" ? "primaryTextColor" : "lightGrey",
        };
      }
      return tab;
    });

    // setListTabs(newTabs);

    return newTabs;
  };

  const handleUpdateBooking = async (key: string, value: any) => {
    setBooking(vals => ({
      ...vals,
      [key]: value,
    }));

    if (currentTab === "DATE") {
      setBookingDate(new Date(value));

      setCurrentTab("PERSONS");

      setIsLoadingBookingAvailabilities(true);

      handleGetBookingAvailabilitiesForDate(new Date(value));
    }

    if (currentTab === "PERSONS") {
      setCurrentTab("TIME");
    }
    if (currentTab === "TIME") {
      setCurrentTab("ADVANCED");
    }
    if (currentTab === "ADVANCED") {
      setCurrentTab("CLIENT");
    }
  };

  const handleUpdateIsNotDeductedFromStock = (value: boolean) => {
    setBooking(vals => ({
      ...vals,
      isNotDeductedFromStock: value,
    }));
  };

  const handleTimeSelect = (serviceId: string, slotId: string, newTime: string) => {
    handleUpdateBooking("serviceId", serviceId);
    handleUpdateBooking("slotId", slotId);

    const [hour, minute] = newTime.split(":");

    const formattedDate = setMinutes(
      setHours(booking.date, parseInt(hour, 10)),
      parseInt(minute, 10),
    );

    setTime(newTime);

    handleUpdateBooking("date", formattedDate);
  };

  const handleUpdateClient = (client: MerchantClientFragment) => {
    setBooking(vals => ({
      ...vals,
      clientId: client?._id || null,
      client: {
        ...vals.client,
        ...client,
      },
    }));
  };

  const handleSetCall = (newCall: PopCallFragment) => {
    setCall(newCall);

    if (newCall.merchantClientId) {
      handleUpdateClient(newCall.merchantClientId);
    }
  };

  const handleAdvancedSelection = (
    selectedTables: SelectedBookingTableFragment[],
    products: ProductFragment[],
    includePrepaid: boolean,
  ) => {
    setBooking(vals => ({
      ...vals,
      tables: selectedTables,
      includePrepaid,
    }));
    setIsAdvancedTabFilled(true);
    setCurrentTab("CLIENT");
  };

  const handlePersonsSelection = (persons: number) => {
    handleUpdateBooking("nbPersons", persons);
  };

  const displayTabContent = () => {
    switch (currentTab) {
      case "DATE":
        return (
          <Box flex={1}>
            <NewBookingDateSelection
              selectedDate={booking?.date}
              onDateSelected={date => handleUpdateBooking("date", date)}
              markedDates={marked}
            />
          </Box>
        );
      case "PERSONS":
        return (
          <Box flex={1}>
            <NewBookingPersonsSelection
              onChange={handlePersonsSelection}
              personsSelected={booking.nbPersons}
              isSplittable={appContext.isSplittable}
            />
          </Box>
        );
      case "TIME":
        return (
          <Box flex={1}>
            <NewBookingTimeSelection
              services={bookingServices}
              selectedServiceId={booking.serviceId}
              selectedSlotId={booking.slotId}
              selectedDate={bookingDate}
              setServiceId={serviceId => handleUpdateBooking("serviceId", serviceId)}
              onSlotSelect={handleTimeSelect}
              nbPersons={booking.nbPersons}
              isLoading={isLoadingBookingAvailabilities}
              isNewBooking
            />
          </Box>
        );
      case "ADVANCED":
        return (
          <NewBookingAdvancedSelection
            booking={booking}
            handleAdvancedSelection={handleAdvancedSelection}
            bookingSettings={bookingSettings}
          />
        );
      case "CLIENT":
        return (
          <Box
            flex={1}
            paddingHorizontal={{
              largeTablet: "s",
            }}
          >
            <ClientSelection
              errors={errors}
              isSnackBarOpen={isSnackBarOpen}
              onCloseSnackBar={() => setIsSnackBarOpen(false)}
              client={booking.client}
              updateClient={handleUpdateClient}
              isNotDeductedFromStock={booking.isNotDeductedFromStock}
              setIsNotDeductedFromStock={handleUpdateIsNotDeductedFromStock}
              call={call}
              updateCall={handleSetCall}
              isOnMainClientPage={isOnMainClientPage}
              setIsOnMainClientPage={setIsOnMainClientPage}
              booking={booking}
              setBooking={setBooking}
              bookingSettings={bookingSettings}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  const handleTabSwitch = (tab: string) => {
    const current = getListTabs().find(t => t.key === currentTab);
    const currentIdx = getListTabs().findIndex(t => t.key === currentTab);
    const nextTab = getListTabs().find(t => t.key === tab);
    const nextTabIdx = getListTabs().findIndex(t => t.key === tab);

    const isSwitchToNextTab = (nexTab?: ListingTab) => {
      if (!nexTab) return false;

      if (currentTab === "DATE" && nexTab.key === "PERSONS") {
        return true;
      } else if (currentTab === "PERSONS" && nexTab.key === "TIME") {
        return true;
      } else if (currentTab === "TIME" && nexTab.key === "ADVANCED") {
        return true;
      } else if (currentTab === "ADVANCED" && nexTab.key === "CLIENT") {
        return true;
      }
    };

    const isToPrevTab = currentIdx > nextTabIdx;

    const isToNextToTab = isSwitchToNextTab(nextTab);

    if (isToPrevTab) {
      setCurrentTab(tab);
    }

    if ((nextTab?.value || isToNextToTab) && current?.value) {
      setCurrentTab(tab);
    }

    if (nextTab?.value !== "CLIENT") {
      setIsOnMainClientPage(true);
    }
    if (nextTab?.value !== "DATE") {
      setBooking(prev => ({
        ...prev,
        serviceId: "",
        slotId: "",
      }));
    }
  };

  const isSubmitButtonDisabled = () => {
    const { clientId, client, nbPersons, date } = booking;

    const IS_CLIENT_SELECTED =
      clientId || (client?.lastName && isPhoneNumberValid(client?.phone));

    return isInProgress || !IS_CLIENT_SELECTED || nbPersons <= 0 || !date;
  };

  const displayNewBookingContent = () => {
    return (
      <Box flex={1} backgroundColor="white">
        {displayTabContent()}

        {currentTab === "CLIENT" && isOnMainClientPage && (
          <Box
            mb="l"
            paddingHorizontal={{
              largeTablet: "s",
            }}
          >
            <Box paddingHorizontal="s">
              <ToggleInputLine
                text="Envoyer notification au client"
                fontSize={12}
                value={booking.sendClientNotifications}
                onValueChange={v =>
                  setBooking(vals => ({ ...vals, sendClientNotifications: v }))
                }
              />
            </Box>
            <Box paddingHorizontal="s" mb="s" flexDirection="row" alignItems="center">
              <Box mr="s" flexDirection="row" alignItems="center">
                <CustomText mr="s" variant="text" color="primaryTextColor">
                  SMS
                </CustomText>

                {booking.client?.phone ? (
                  <VALIDATE width={17} height={17} fill={PALETTE.green} />
                ) : (
                  <CANCEL fill={PALETTE.red} />
                )}
              </Box>
              <Box mr="s" flexDirection="row" alignItems="center">
                <CustomText mr="s" variant="text" color="primaryTextColor">
                  Email
                </CustomText>

                {booking.client?.email ? (
                  <VALIDATE width={17} height={17} fill={PALETTE.green} />
                ) : (
                  <CANCEL fill={PALETTE.red} />
                )}
              </Box>
            </Box>

            <CustomButton
              buttonVariant="primaryButton"
              buttonColor={isSubmitButtonDisabled() ? "lightGrey" : "success"}
              onPress={handleNewBooking}
              disabled={isSubmitButtonDisabled()}
            >
              <CustomText variant="primaryButtonText" color="white">
                Enregistrer
              </CustomText>
            </CustomButton>
          </Box>
        )}
      </Box>
    );
  };

  const displayNonSplittableContent = () => {
    if (appContext.isSplittable) return;

    return displayNewBookingContent();
  };

  const displayRightComponent = () => {
    if (!appContext.isSplittable) return;

    return (
      <Box flex={1} backgroundColor="white" pt="m">
        {displayNewBookingContent()}
      </Box>
    );
  };

  return (
    <SplitView
      leftComponent={
        <Box flex={1} paddingHorizontal="s" pt="m" backgroundColor="white">
          <Box mb="s">
            <ScreenHeader
              title="Nouvelle réservation"
              hasBackButton
              onBackPress={navigation.goBack}
            />
          </Box>

          <Box mb="s">
            <ListingTabs
              tabs={getListTabs()}
              horizontal={!appContext.isSplittable}
              currentTabName={currentTab}
              onSelectTab={handleTabSwitch}
              boxProps={{
                minHeight: 45,
              }}
            />
          </Box>

          {displayNonSplittableContent()}
        </Box>
      }
      rightComponent={displayRightComponent()}
    />
  );
};

export default NewBooking;
