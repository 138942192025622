import { useFocusEffect } from "@react-navigation/native";
import React, { useContext, useState } from "react";

import COMMENT from "../../../assets/icons/BASE/COMMENT.svg";
import { ErrorInfoSuccessAlertModalContext } from "../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { GetMerchantSmsInformationQuery } from "../../graphql/generated/schema";
import {
  useBuildPaymentIntentForSmsPackPurchaseMutation,
  useGetMerchantSettingsLazyQuery,
  useGetMerchantSmsInformationLazyQuery,
  usePaySmsTransactionMutation,
} from "../../graphql/generated/schema";
import { PALETTE } from "../../theme/Palette";
import { handleConfirmPayment } from "../../utils/Stripe";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";

import SmsPacksPurchaseModal from "./SmsPacksPurchaseModal";

const SmsPackDashboard = () => {
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);
  const [smsInfo, setSmsInfo] = useState<
    GetMerchantSmsInformationQuery["getMerchantSmsInformation"] | null
  >(null);
  const [defaultPm, setDefaultPm] = useState("");

  const [getSmsInfo] = useGetMerchantSmsInformationLazyQuery();
  const [buildPaymentInforSmsPackPurchase] =
    useBuildPaymentIntentForSmsPackPurchaseMutation();
  const [purchaseSmsPack] = usePaySmsTransactionMutation();

  const [getMerchantSettings] = useGetMerchantSettingsLazyQuery();

  const handleGetMerchantSettings = async () => {
    try {
      const { data } = await getMerchantSettings();

      if (data?.getMerchantSettings) {
        setDefaultPm(data?.getMerchantSettings?.paymentMethods.defaultPaymentMethodId);
      }
    } catch (err) {
      console.log("err get merchant settings", err);
    }
  };

  const handleGetMerchantSmsInfo = async () => {
    try {
      const { data } = await getSmsInfo({
        fetchPolicy: "cache-and-network",
      });

      if (data?.getMerchantSmsInformation) {
        setSmsInfo(data.getMerchantSmsInformation);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetMerchantSmsInfo();
      handleGetMerchantSettings();
    }, []),
  );

  const handlePurchaseSubmit = async (smsPackId: string) => {
    try {
      const { data: paymentIntentInfo } = await buildPaymentInforSmsPackPurchase({
        variables: {
          smsPackId,
        },
      });

      if (!paymentIntentInfo?.buildPaymentIntentForSmsPackPurchase) {
        infoAlert.openAlert(
          "Erreur",
          [
            {
              code: "ERR_BUILD_PAYMENT_INTENT_FOR_SMS_PACK_PURCHASE",
              message: "Erreur lors de la création du paiement",
            },
          ],
          "error",
        );
        return;
      }

      const { buildPaymentIntentForSmsPackPurchase } = paymentIntentInfo;

      if (!buildPaymentIntentForSmsPackPurchase?.paymentIntentSecret) {
        infoAlert.openAlert(
          "Erreur",
          [
            {
              code: "ERR_BUILD_PAYMENT_INTENT_FOR_SMS_PACK_PURCHASE",
              message: "Erreur lors de la création du paiement",
            },
          ],
          "error",
        );
        return;
      }

      const { paymentIntent, paymentIntentSecret, customerId, ephemeralCustomerId } =
        buildPaymentIntentForSmsPackPurchase;

      const response = await handleConfirmPayment(
        paymentIntentSecret,
        defaultPm || "",
        customerId,
        ephemeralCustomerId,
      );

      if (response?.error) {
        if (response?.error?.code === "Canceled") return;
        infoAlert.openAlert(
          "Erreur",
          [
            {
              code: "ERR_BUILD_PAYMENT_INTENT_FOR_SMS_PACK_PURCHASE",
              message:
                response?.error?.localizedMessage ||
                "Erreur lors de la création du paiement",
            },
          ],
          "error",
        );
        return;
      }

      await purchaseSmsPack({
        variables: {
          smsPackId,
          paymentIntentId: paymentIntent,
        },
      });
    } catch (err) {
      console.log("err purchasing sms pack", err);

      if (err?.message === "MERCHANT_DOES_NOT_HAVE_VALID_PAYMENT_METHOD") {
        infoAlert.openAlert(
          "Erreur",
          [
            {
              code: "ERR_BUILD_PAYMENT_INTENT_FOR_SMS_PACK_PURCHASE",
              message:
                "Vous n'avez pas de moyen de paiement valide ou  un moyen de paiement par défault.",
            },
          ],
          "error",
        );
      } else {
        infoAlert.openAlert(
          "Erreur",
          [
            {
              code: "ERR_BUILD_PAYMENT_INTENT_FOR_SMS_PACK_PURCHASE",
              message: "Erreur lors de la création du paiement",
            },
          ],
          "error",
        );
      }
    } finally {
      await handleGetMerchantSmsInfo();
    }
  };

  const displayRemainingSms = () => {
    const total = smsInfo?.totalSmsAmount || 0;
    const used = smsInfo?.usedSmsAmount || 0;

    if (total - used) {
      return total - used;
    } else {
      return 0;
    }
  };

  return (
    <Box
      mt={{
        tablet: "s",
        phone: "m",
      }}
      marginHorizontal="s"
      borderRadius="button"
      backgroundColor="white"
      p="s"
      height="100%"
      // paddingVertical="m"
    >
      <Box mb="s">
        <CustomText variant="title" color="primaryTextColor">
          Forfait SMS
        </CustomText>

        <Box position="absolute" top={-20} right={-5}>
          <COMMENT height={50} width={50} fill={PALETTE.darkBlue} />
        </Box>
      </Box>

      <Box mt="m">
        <Box>
          <Box
            width={50}
            height={50}
            backgroundColor="purple"
            p="s"
            borderRadius="button"
            alignItems="center"
            justifyContent="center"
          >
            <COMMENT height={30} width={30} fill={PALETTE.white} />
          </Box>
          <Box>
            <CustomText fontSize={40} mt="s" variant="label" color="primaryTextColor">
              {displayRemainingSms()}
            </CustomText>
            <CustomText mt="s" variant="content" color="primaryTextColor">
              SMS restants
            </CustomText>
          </Box>
        </Box>
      </Box>

      <Box mt="m">
        <CustomButton
          onPress={() => setIsPurchaseModalOpen(true)}
          buttonVariant="outlineButton"
          borderColor="success"
        >
          <CustomText variant="outlineButtonText" color="success">
            Cliquez pour recharger
          </CustomText>
        </CustomButton>
      </Box>

      <SmsPacksPurchaseModal
        isOpen={isPurchaseModalOpen}
        onClose={() => setIsPurchaseModalOpen(false)}
        onSubmit={handlePurchaseSubmit}
      />
    </Box>
  );
};

export default SmsPackDashboard;
