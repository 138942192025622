import { useApolloClient } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { startOfDay } from "date-fns";
import { useEffect, useState } from "react";
import { Dimensions, Image } from "react-native";
import { LocaleConfig } from "react-native-calendars";

LocaleConfig.locales.fr = {
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  monthNamesShort: [
    "Janv.",
    "Févr.",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juil.",
    "Août",
    "Sept.",
    "Oct.",
    "Nov.",
    "Déc.",
  ],
  dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
  dayNamesShort: ["Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."],
  today: "Aujourd'hui",
};

LocaleConfig.defaultLocale = "fr";

import { NavigationContainer } from "@react-navigation/native";
import * as Notifications from "expo-notifications";
import { GestureHandlerRootView } from "react-native-gesture-handler";

import Box from "../components/Base/Box";
import ErrorInfoMessageAlert from "../components/ErrorInfoMessageAlert";
import InProgressLoader from "../components/InProgressLoader/index";
import InternetConnectivity from "../components/InternetConnectivity/index";
import SwitchStaffAccount from "../components/SwitchStaffAccount/index";
import { link } from "../config/ApolloClient";
import { AppContext } from "../contexts/AppContext";
import { BookingContext } from "../contexts/BookingContext";
import { ErrorInfoSuccessAlertModalContext } from "../contexts/ErrorInfoSuccessAlertModalContext";
import { SwitchStaffAccountContext } from "../contexts/SwitchStaffAccount";
import type {
  ConnectedUserFragment,
  Modules,
  SelectedBookingTableFragment,
} from "../graphql/generated/schema";
import {
  useAddMerchantDeviceMutation,
  useDeletMerchantDeviceMutation,
  useGetAlertsLazyQuery,
  useGetConnectedUserLazyQuery,
  useGetMerchantLazyQuery,
} from "../graphql/generated/schema";
import { AppStateProvider } from "../providers/AppState/index";
import KeyboardAvoidViewProvider from "../providers/keyboardAvoidViewProvider/index";
import { captureAndReportErrors } from "../sentry";
import {
  STORAGE_KEYS,
  getDeviceInfo,
  getFromStorage,
  registerForPushNotificationsAsync,
  removeFromStorage,
  storeToStorage,
} from "../utils/Login";
import type { ERROR } from "../utils/common";

import AppStack from "./AppStack";
import AuthStack from "./AuthStack/AuthStack";

const Navigation = () => {
  const [loading, setLoading] = useState(true);
  const [licenceKey, setLicenceKey] = useState("");
  const [apiToken, setApiToken] = useState("");
  const [deviceToken, setDeviceToken] = useState("");
  const [isOwner, setIsOwner] = useState(false);
  const [staffId, setStaffId] = useState("");
  const [connectedUser, setConnectedUser] = useState<ConnectedUserFragment | null>(null);
  const [merchantPermissions, setMerchantPermissions] = useState<Modules[]>([]);
  const [isSwitchStaffAccountModalOpen, setIsSwitchStaffAccountModalOpen] =
    useState(false);

  const [isInfoAlertOpen, setIsInfoAlertOpen] = useState(false);
  const [infoAlertType, setInfoAlertType] = useState<"error" | "info" | "success">(
    "error",
  );
  const [infoAlertTitle, setInfoAlertTitle] = useState("");
  const [infoAlertErrors, setInfoAlertErrors] = useState<ERROR[]>([]);
  const [hideHeaders, setHideHeaders] = useState(false);
  const [isSplittable] = useState(Dimensions.get("window").width > 600);

  const [totalPendingBookings, setTotalPendingBookings] = useState(0);
  const [totalPendingOrders, setTotalPendingOrders] = useState(0);
  const [totalAlerts, setTotalAlerts] = useState(0);

  const [isPendingLoaderOpen, setIsPendingLoaderOpen] = useState(false);

  const [tablesForBookingAssignment, setTablesForBookingAssignment] = useState<
    SelectedBookingTableFragment[]
  >([]);

  const [addDevice] = useAddMerchantDeviceMutation();
  const [deleteDevice] = useDeletMerchantDeviceMutation();
  const [getMerchant] = useGetMerchantLazyQuery();

  const [getConnectedUser] = useGetConnectedUserLazyQuery();
  const [getAlerts] = useGetAlertsLazyQuery();

  const logoutLink = onError(({ networkError }) => {
    try {
      if (networkError?.statusCode === 401) {
        logOutUser();
      }
    } catch (err) {
      captureAndReportErrors(err);
    }
  });

  const apolloClient = useApolloClient();

  apolloClient.setLink(logoutLink.concat(link));

  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: false,
      shouldSetBadge: true,
    }),
  });

  const handleGetConnectedUser = async () => {
    try {
      const { data } = await getConnectedUser({
        fetchPolicy: "network-only",
      });

      if (data) {
        if (!data.getConnectedUser) logOutUser();
        else {
          setConnectedUser(data.getConnectedUser);
        }
      }
    } catch (err) {
      console.log("ERR GET CONNECTED USER", err);
    }
  };

  const handleGetMerchant = async () => {
    try {
      const { data } = await getMerchant({
        fetchPolicy: "cache-and-network",
      });

      if (data) {
        if (!data.getMerchant) logOutUser();
        else {
          setMerchantPermissions(data.getMerchant.permissions);
        }
      }
    } catch (err) {
      console.log("ERR GET CONNECTED USER", err);
    }
  };

  const handleAppRefresh = async () => {
    try {
      const appToken = await getFromStorage(STORAGE_KEYS.API_TOKEN);
      const staffIdentifer = await getFromStorage(STORAGE_KEYS.STAFF_ID);
      const isMerchantOwner = await getFromStorage(STORAGE_KEYS.IS_OWNER);
      const key = await getFromStorage(STORAGE_KEYS.LICENCE_KEY);
      const deviceId = await getFromStorage(STORAGE_KEYS.DEVICE_ID);

      setStaffId(staffIdentifer || "");
      setIsOwner(isMerchantOwner === "true");
      setLicenceKey(key || "");
      setDeviceToken(deviceId || "");

      if (appToken) {
        // !FIXME validate api token
        // !FIXME get user info
        setApiToken(appToken);
        await handleGetConnectedUser();
        await handleGetMerchant();
        // get user info here
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleGetAlerts = async () => {
    try {
      if (!apiToken) return;

      const response = await getAlerts({
        fetchPolicy: "cache-and-network",
        variables: {
          date: startOfDay(new Date()).toISOString(),
        },
      });

      if (response?.data?.getAlerts) {
        const { data } = response;
        const total =
          data.getAlerts.totalPendingBookings + data.getAlerts.totalPendingOrders;

        setTotalPendingBookings(data.getAlerts.totalPendingBookings);
        setTotalPendingOrders(data.getAlerts.totalPendingOrders);
        setTotalAlerts(total);
      }
    } catch (err) {
      console.log("get alerts err", err);
    }
  };

  const handleInit = async () => {
    try {
      await handleAppRefresh();
      await handleGetAlerts();
    } catch (err) {
      captureAndReportErrors(err);
    }
  };

  useEffect(() => {
    handleInit();
  }, []);

  const handleOpenAlert = (
    title: string,
    errors: ERROR[],
    type: "error" | "info" | "success",
  ) => {
    setInfoAlertTitle(title);
    setInfoAlertType(type);
    setInfoAlertErrors(errors);
    setIsInfoAlertOpen(true);
  };

  const openInProgressLoader = () => {
    setIsPendingLoaderOpen(true);
  };

  const closeInProgressLoader = () => {
    setIsPendingLoaderOpen(false);
  };

  const handleCloseAlert = () => {
    setIsInfoAlertOpen(false);
  };

  const handleAddDevice = async () => {
    try {
      const device = getDeviceInfo();

      const expoPushToken = (await registerForPushNotificationsAsync()) || "";

      setDeviceToken(expoPushToken);

      const { data } = await addDevice({
        variables: {
          deviceInfo: {
            device: {
              deviceToken: expoPushToken,
              manufacturer: device?.manufacturer || device?.brand || "",
              modelName: device?.modelName || "",
            },
          },
        },
      });

      if (data?.addMerchantDevice) {
        await storeToStorage(STORAGE_KEYS.DEVICE_ID, data?.addMerchantDevice?._id);
      }
    } catch (err) {
      console.log("ERROR_ADDING_DEVICE", err);
      captureAndReportErrors(err);
    }
  };

  const loginUser = async (token: string, key: string, staffIdentifer = "") => {
    try {
      const isMerchantOwner = !staffIdentifer;

      setApiToken(token);
      setLicenceKey(key);
      setIsOwner(isOwner);
      setStaffId(staffIdentifer || "");

      await storeToStorage(STORAGE_KEYS.API_TOKEN, token);
      await storeToStorage(STORAGE_KEYS.STAFF_ID, staffIdentifer);
      await storeToStorage(STORAGE_KEYS.IS_OWNER, isMerchantOwner.toString());
      await storeToStorage(STORAGE_KEYS.LICENCE_KEY, key);

      await handleGetConnectedUser();
      await handleGetMerchant();
      await handleAddDevice();
    } catch (err) {
      console.log("err add device", err);
    }
  };

  const logOutUser = async () => {
    try {
      setApiToken("");
      setLicenceKey("");
      setDeviceToken("");
      setIsOwner(false);

      try {
        const id = await getFromStorage(STORAGE_KEYS.DEVICE_ID);

        if (id) {
          await deleteDevice({
            variables: {
              id,
            },
          });
        }
        await removeFromStorage(STORAGE_KEYS.DEVICE_ID);
        await removeFromStorage(STORAGE_KEYS.API_TOKEN);
        await removeFromStorage(STORAGE_KEYS.STAFF_ID);
        await removeFromStorage(STORAGE_KEYS.IS_OWNER);
        await removeFromStorage(STORAGE_KEYS.LICENCE_KEY);

        await apolloClient.clearStore();
      } catch (err) {
        console.log("delete device", JSON.stringify(err, null, 2));
      }

      // remove api token from storage here
      // remove device association here
    } catch (err) {
      console.log("err logout", err);
    }
  };

  const displayContent = () => {
    if (loading)
      return (
        <Box
          flex={1}
          justifyContent="center"
          alignItems="center"
          backgroundColor="primaryTextColor"
        >
          <Image
            source={require("../../assets/splash.png")}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
      );
    return apiToken ? <AppStack /> : <AuthStack />;
  };

  return (
    <AppStateProvider apiToken={apiToken}>
      <AppContext.Provider
        value={{
          licenceKey,
          apiToken,
          isOwner,
          staffId,
          deviceToken,
          hideHeaders,
          connectedUser,
          merchantPermissions,

          totalAlerts,
          totalPendingBookings,
          totalPendingOrders,

          refreshAlerts: handleGetAlerts,
          refreshApp: handleAppRefresh,
          refreshMerchantPermissions: handleGetMerchant,

          isSplittable,
          setHideHeaders,

          loginUser,
          logOutUser,
        }}
      >
        <BookingContext.Provider
          value={{
            tablesForBookingAssignment,
            setTablesForBookingAssignment: setTablesForBookingAssignment,
          }}
        >
          <SwitchStaffAccountContext.Provider
            value={{
              isStaffAccountModalOpen: isSwitchStaffAccountModalOpen,
              setIsStaffAccountModalOpen: setIsSwitchStaffAccountModalOpen,
            }}
          >
            <ErrorInfoSuccessAlertModalContext.Provider
              value={{
                isOpen: isInfoAlertOpen,
                title: infoAlertTitle,
                errors: infoAlertErrors,
                type: infoAlertType,

                openAlert: handleOpenAlert,
                closeAlert: handleCloseAlert,

                isPendingLoaderOpen,
                openInProgressLoader,
                closeInProgressLoader,
              }}
            >
              <GestureHandlerRootView style={{ flex: 1 }}>
                <KeyboardAvoidViewProvider>
                  <NavigationContainer
                    linking={{
                      prefixes: ["pro.eiwie://", "https://pro.eiwie.com"],
                      config: {
                        screens: {
                          LOGIN: {
                            path: "login",
                          },
                          DASHBOARD: {
                            path: "dashboard",
                          },
                          CASH_REGISTER_TAB: {},
                          MERCHANT_STRIPE_ACCOUNT_RETURN:
                            "/merchant-stripe-account-return",
                          SETTINGS: {
                            path: "settings",
                            screens: {
                              ADD_SUBSCRIPTION: {
                                path: "add-subscription",
                              },
                            },
                          },

                          // NOT_FOUND: "*",
                        },
                      },
                    }}
                  >
                    {displayContent()}
                  </NavigationContainer>
                </KeyboardAvoidViewProvider>
              </GestureHandlerRootView>

              <ErrorInfoMessageAlert
                isOpen={isInfoAlertOpen}
                onClose={handleCloseAlert}
                type={infoAlertType}
                title={infoAlertTitle}
                data={infoAlertErrors}
              />

              <InProgressLoader isLoading={isPendingLoaderOpen} />

              <InternetConnectivity />

              <SwitchStaffAccount />
            </ErrorInfoSuccessAlertModalContext.Provider>
          </SwitchStaffAccountContext.Provider>
        </BookingContext.Provider>
      </AppContext.Provider>
    </AppStateProvider>
  );
};

export default Navigation;
