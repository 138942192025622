import { useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import React, { useContext, useEffect, useMemo, useState } from "react";

import BOOKING_FLOOR from "../../../../assets/icons/BASE/BOOKING_FLOOR.svg";
import LEFT_ARROW from "../../../../assets/icons/BASE/LEFT_ARROW.svg";
import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import BookingFloorTableViewModal from "../../../components/BookingFloorTableViewModal";
import { CustomButton } from "../../../components/Button";
import Floor from "../../../components/Floor";
import Loader from "../../../components/Loader";
import { MODULE_TYPE } from "../../../components/ModuleList/common";
import ScreenHeader from "../../../components/ScreenHeader";
import { BookingContext } from "../../../contexts/BookingContext";
import type {
  BookingFloorFragment,
  BookingFloorWithBookingsFragment,
  BookingSettingsFragment,
  Pagination,
  SelectedBookingTableFragment,
  TableFragment,
} from "../../../graphql/generated/schema";
import {
  useGetBookingFloorsWithBookingsLazyQuery,
  useGetBookingSettingsLazyQuery,
} from "../../../graphql/generated/schema";
import { captureAndReportErrors } from "../../../sentry";
import { PALETTE } from "../../../theme/Palette";
import { BOOKING_FLOOR_VIEW_TYPE } from "../../../types";

interface BookingFloorsProps {
  selectedTables?: SelectedBookingTableFragment[];
  onSubmit?: (tables: SelectedBookingTableFragment[]) => void;
  selectedDate?: Date;
  bookingPersons?: number;
  bookingFloorViewType?: BOOKING_FLOOR_VIEW_TYPE;
  bookingFloors?: BookingFloorWithBookingsFragment[];
  goBack?: () => void;
  selectedBookingServiceId?: string;
}

const BookingFloors = ({
  selectedTables: sTables = [],
  onSubmit: submit,
  selectedDate: date,
  bookingFloorViewType: viewType,
  bookingPersons: persons,
  bookingFloors: initialFloors = [],
  goBack,
  selectedBookingServiceId: serviceId,
}: BookingFloorsProps) => {
  const { params = {} } = useRoute();
  const bookingContext = useContext(BookingContext);
  // const {
  //   selectedTables = [],
  //   onSubmit = (t: SelectedBookingTableFragment[]) => {},
  //   selectedDate = new Date(),
  //   bookingPersons = 0,
  //   bookingFloorViewType = BOOKING_FLOOR_VIEW_TYPE.TABLE_VIEW,
  // } = params;

  const selectedTables = params?.selectedTables || sTables;
  const onSubmit = params?.onSubmit || submit;
  const selectedDate = params?.selectedDate || date;
  const bookingPersons = params?.bookingPersons || persons;
  const bookingFloorViewType = params?.bookingFloorViewType || viewType;
  const selectedBookingServiceId = params?.selectedBookingServiceId || serviceId;

  const [pagination, setPagination] = useState<Pagination>({ limit: 30, offset: 0 });
  const [tableIds, setTableIds] = useState<string[]>(
    selectedTables.map(t => t.tableId) || [],
  );
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [bookingFloors, setBookingFloors] =
    useState<BookingFloorWithBookingsFragment[]>(initialFloors);
  const [tableIdForView, setTableIdForView] = useState<TableFragment | undefined>();
  const [bookingSettings, setBookingSettings] = useState<
    BookingSettingsFragment | undefined
  >();

  const [getBookingFloorsWithBookings] = useGetBookingFloorsWithBookingsLazyQuery();
  const [getBookingSettings] = useGetBookingSettingsLazyQuery();

  const handleGetBookingSettings = async () => {
    try {
      const { data } = await getBookingSettings({
        fetchPolicy: "cache-and-network",
      });

      if (data) {
        setBookingSettings(data.getBookingSettings);
      }
    } catch (err) {
      console.log("err", err);
      captureAndReportErrors(err);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetBookingSettings();
    }, []),
  );

  const handleGetBookingsFloors = async () => {
    try {
      if (initialFloors.length > 0) return [];
      const { data } = await getBookingFloorsWithBookings({
        variables: {
          pagination,
          selectedDate: selectedDate,
          selectedServiceId:
            selectedBookingServiceId && selectedBookingServiceId !== "ALL"
              ? selectedBookingServiceId
              : null,
        },
        fetchPolicy: "cache-and-network",
      });
      if (data) {
        const floors = data.getBookingFloors.filter(floor => floor.tables.length > 0);

        // console.log("floors", JSON.stringify(floors, null, 2));

        setBookingFloors(floors);
        return floors;
      }
    } catch (err) {
      console.log("err", err);
      captureAndReportErrors(err);
    } finally {
      setLoading(false);
    }

    return [];
  };

  useEffect(() => {
    if (initialFloors.length > 0) {
      setBookingFloors(initialFloors);
    }
  }, [initialFloors]);

  const tables = useMemo(
    () =>
      bookingFloors.reduce((acc, floor) => {
        const floorTables = floor.tables.map(table => ({
          ...table,
          prefix: floor.prefix,
          tableId: table._id,
          floorId: floor._id,
        }));
        return [...acc, ...floorTables];
      }, [] as BookingFloorFragment["tables"]),
    [bookingFloors],
  );

  const handleTableSelection = (tableId: string) => {
    if (bookingFloorViewType === BOOKING_FLOOR_VIEW_TYPE.TABLE_ASSIGNMENT) {
      if (tableIds.includes(tableId)) {
        setTableIds(tableIds.filter(id => id !== tableId));
      } else {
        setTableIds([...tableIds, tableId]);
      }
    } else {
      setTableIdForView(tables.find(t => t._id === tableId));
    }
  };

  const handleRefreshTable = async () => {
    try {
      if (!tableIdForView) return;

      const { _id: tableId, floorId } = tableIdForView;
      const floors = await handleGetBookingsFloors();

      const selectedFloor = floors.find(floor => floor._id === floorId);

      if (selectedFloor) {
        const selectedTable = selectedFloor.tables.find(table => table._id === tableId);

        if (selectedTable) {
          setTableIdForView({
            ...selectedTable,
            prefix: selectedFloor.prefix,
            floorId: selectedFloor._id,
            tableId: selectedTable._id,
          });
        }
      }
    } catch (err) {
      captureAndReportErrors(err);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetBookingsFloors();
    }, [selectedDate]),
  );

  if (loading) {
    return <Loader />;
  }

  if (bookingFloors.length === 0) {
    return (
      <Box flex={1} backgroundColor="white">
        {bookingFloorViewType === BOOKING_FLOOR_VIEW_TYPE.TABLE_ASSIGNMENT && (
          <Box m="s">
            <ScreenHeader
              title="Plan de salles"
              hasBackButton
              onBackPress={goBack || navigation.goBack}
            />
          </Box>
        )}

        <Box alignItems="center" justifyContent="center">
          <CustomText variant="label" color="primaryTextColor">
            Aucun plan de salle disponible. Veuillez créer un plan de salle dans le module
            "Plan de salle".
          </CustomText>
        </Box>
      </Box>
    );
  }

  const selectedTablesPersons = tables
    .filter(table => tableIds.includes(table._id))
    .reduce((acc, table) => acc + table.maxCapacity, 0);

  const handleOnSubmit = () => {
    const selectedTablesForSubmit: SelectedBookingTableFragment[] = tables
      .filter(table => tableIds.includes(table._id))
      .map(t => ({
        tableId: t._id,
        floorId: t.floorId,
        prefix: t.prefix,
        tableNumber: t.tableNumber,
        minCapacity: t.minCapacity,
        maxCapacity: t.maxCapacity,
      }));

    bookingContext.setTablesForBookingAssignment(selectedTablesForSubmit);

    onSubmit(selectedTablesForSubmit);
  };

  const BUTTON_COLOR = selectedTablesPersons >= bookingPersons ? "success" : "orange";
  const ICON_COLOR = selectedTablesPersons >= bookingPersons ? "green" : "orange";

  return (
    <Box flex={1} backgroundColor="white">
      <Floor
        title="Plan de salles"
        goBack={goBack || navigation.goBack}
        selectedTables={tableIds}
        moduleType={MODULE_TYPE.BOOKING}
        bookingFloors={bookingFloors}
        handleTableSelection={handleTableSelection}
        bookingFloorViewType={bookingFloorViewType}
        bookingSettings={bookingSettings}
        selectedBookingServiceId={selectedBookingServiceId}
      />

      {bookingFloorViewType === BOOKING_FLOOR_VIEW_TYPE.TABLE_ASSIGNMENT && (
        <Box
          backgroundColor="white"
          position="absolute"
          p="s"
          pb="xl"
          bottom={10}
          left={0}
          right={0}
        >
          <CustomButton
            buttonVariant="outlineButton"
            borderColor={BUTTON_COLOR}
            onPress={handleOnSubmit}
            overrideStyles={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box />
            <Box flex={1}>
              <CustomText variant="primaryButtonText" color={BUTTON_COLOR}>
                {selectedTablesPersons} / {bookingPersons} pax
              </CustomText>
            </Box>
            <Box
              flex={0.2}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              mr="s"
            >
              {/* <Box height="100%" width={5} backgroundColor="danger" /> */}

              <BOOKING_FLOOR fill={PALETTE[ICON_COLOR]} />

              <LEFT_ARROW width={12} height={12} fill={PALETTE[ICON_COLOR]} />
            </Box>
          </CustomButton>
        </Box>
      )}

      <BookingFloorTableViewModal
        isOpen={!!tableIdForView}
        setIsOpen={() => setTableIdForView(undefined)}
        table={tableIdForView}
        handleRefreshTable={handleRefreshTable}
        selectedBookingServiceId={selectedBookingServiceId}
      />
    </Box>
  );
};

export default BookingFloors;
