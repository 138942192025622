import { useContext, useState } from "react";
import { TouchableOpacity } from "react-native";

import BOOKING_FLOOR_VIEW from "../../../assets/icons/BASE/BOOKING_FLOOR_VIEW.svg";
import DRAGGABLE from "../../../assets/icons/BASE/DRAGGABLE.svg";
import type {
  BookingFloorWithBookingsFragment,
  BookingSettingsFragment,
} from "../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import type { BOOKING_FLOOR_VIEW_TYPE } from "../../types";
import Box from "../Base/Box";
import type { MODULE_TYPE } from "../ModuleList/common";
import ScreenHeader from "../ScreenHeader";

import FloorView from "./FloorView";
import TablesView from "./TablesView";
import { AppContext } from "../../contexts/AppContext";

interface FloorProps {
  title: string;
  goBack: () => void;
  selectedTables: string[];
  moduleType: MODULE_TYPE;
  handleTableSelection: (tableId: string) => void;
  // selectedDate: Date;
  bookingFloors: BookingFloorWithBookingsFragment[];
  bookingFloorViewType: BOOKING_FLOOR_VIEW_TYPE;
  bookingSettings?: BookingSettingsFragment;
  selectedBookingServiceId?: string;
  // onSubmit: (selectedTables: SelectedBookingTableFragment[]) => void;
}

const Floor = ({
  title,
  goBack,
  // selectedDate,
  selectedTables,
  bookingFloors,
  bookingFloorViewType,
  handleTableSelection,
  moduleType,
  bookingSettings,
  selectedBookingServiceId,
}: FloorProps) => {
  const [isInBookingFloorView, setIsInBookingFloorView] = useState(true);
  const appContext = useContext(AppContext);

  const displayContent = () => {
    if (isInBookingFloorView) {
      return (
        <FloorView
          bookingFloors={bookingFloors}
          handleTableSelection={handleTableSelection}
          tableIds={selectedTables}
          moduleType={moduleType}
          bookingSettings={bookingSettings}
          selectedBookingServiceId={selectedBookingServiceId}
          isSplittable={appContext.isSplittable}
        />
      );
    }

    return (
      <TablesView
        bookingFloors={bookingFloors}
        handleTableSelection={handleTableSelection}
        tableIds={selectedTables}
        selectedIds={selectedTables}
        moduleType={moduleType}
        bookingFloorViewType={bookingFloorViewType}
        selectedBookingServiceId={selectedBookingServiceId}
      />
    );
  };

  return (
    <Box flex={1} pt="m" backgroundColor="white">
      <Box mb="m">
        <ScreenHeader
          title={title}
          hasBackButton
          onBackPress={goBack}
          rightButtons={
            <Box mr="m" flexDirection="row" alignItems="center">
              <TouchableOpacity onPress={() => setIsInBookingFloorView(true)}>
                <Box
                  borderColor="success"
                  borderWidth={isInBookingFloorView ? LINE_THICKNESS : 0}
                  p="xs"
                  borderRadius="button"
                >
                  <BOOKING_FLOOR_VIEW fill={PALETTE.darkBlue} />
                </Box>
              </TouchableOpacity>
              <TouchableOpacity
                style={{ marginLeft: 8 }}
                onPress={() => setIsInBookingFloorView(false)}
              >
                <Box
                  borderColor="success"
                  borderWidth={!isInBookingFloorView ? LINE_THICKNESS : 0}
                  p="xs"
                  borderRadius="button"
                >
                  <DRAGGABLE height={18} width={18} fill={PALETTE.darkBlue} />
                </Box>
              </TouchableOpacity>
            </Box>
          }
        />
      </Box>

      {displayContent()}
    </Box>
  );
};

export default Floor;
