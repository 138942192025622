import {
  addSeconds,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  isAfter,
  isBefore,
  isEqual,
  set,
  startOfMinute,
} from "date-fns";
import omitDeep from "deepdash-es/omitDeep";
import uuid from "react-native-uuid";

import type { SelectedBookingTableFragment } from "../graphql/generated/schema";
import { Event_Type, Origin } from "../graphql/generated/schema";

export const formatCurrencyPrice = (
  price: number,
  language: string,
  currency: string,
) => {
  const formatter = Intl.NumberFormat(language, {
    style: "currency",
    currency: currency,
  });

  return formatter.format(price);
};

export const formatKeyboardValue = (value: string, locale: string) => {
  Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(+value);
};

export const getUID = (): string => {
  const uid = uuid.v4();

  if (typeof uid === "string") return uid;
  return uid.toString();
};

export const uniqueInArr = a => [...new Set(a)];

export const displayModuleId = (id = "") => {
  const formattedId = `#${id.slice(0, 10)}`;
  return formattedId;
};

export const getWeekdayFromNumber = (day: number) => {
  const weekdays = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];
  return weekdays[day];
};

export const createDateWithTime = (date: Date, time: string) => {
  const hours = +time.split(":")[0];
  const minutes = +time.split(":")[1];

  const formattedDate = set(date, {
    hours,
    minutes,
  });

  const newDate = startOfMinute(formattedDate);

  return newDate;
};

export const removeTypeNames = (obj: Record<string, unknown>) => {
  return omitDeep(obj, "__typename");
};

export const displayTables = (tables: SelectedBookingTableFragment[]) => {
  const nbTables = tables.length;
  if (nbTables === 0) return;
  const [firstTable] = tables;
  const suffix = nbTables > 1 ? `(+${nbTables - 1})` : "";
  return `${firstTable?.prefix?.slice(0, 3)}-${
    firstTable?.tableNumber || "-"
  } ${suffix} `;
};

export interface ERROR {
  path?: string;
  code: string;
  message: string;
}

export const formaYupErrors = (err: Record<string, unknown>) => {
  if (!err?.inner && err?.message) return [{ code: "error", message: err.message }];

  const formattedErrors: ERROR[] = err?.inner?.map(val => ({
    path: val.path,
    code: val.path,
    message: val.errors.join("\n"),
  }));

  return formattedErrors || [];
};

export const formattedTime = (seconds: number) => {
  const helperDate = addSeconds(new Date(0), seconds);
  return format(helperDate, "mm:ss");
};

export const getDifferencesBetweenDates = (date: Date, compareDate: Date) => {
  const seconds = differenceInSeconds(date, compareDate) % 60;
  const minutes = differenceInMinutes(date, compareDate) % 60;
  const hours = differenceInHours(date, compareDate);

  const paddedSeconds = seconds.toString().padStart(2, "0");
  const paddedMinutes = minutes.toString().padStart(2, "0");
  const paddedHours = hours.toString().padStart(2, "0");

  const formattedTimeDifference = `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;

  return {
    formattedTimeDifference,
    seconds,
    minutes,
    hours,
    paddedSeconds,
    paddedMinutes,
    paddedHours,
  };
};

export const formatTimeForSeatedBooking = (date: Date, compareDate: Date) => {
  const seconds = differenceInSeconds(date, compareDate) % 60;
  const minutes = differenceInMinutes(date, compareDate) % 60;
  const hours = differenceInHours(date, compareDate);

  const paddedSeconds = seconds.toString().padStart(2, "0");
  const paddedMinutes = minutes.toString().padStart(2, "0");
  const paddedHours = hours.toString().padStart(2, "0");

  if (hours < 1) return `${paddedMinutes}:${paddedSeconds}`;
  else return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};

export const compactNumber = (num: number, locale: string) => {
  const formatter = Intl.NumberFormat(locale, { notation: "compact" });
  return formatter.format(num);
};

export const PHONE_VALIDATION_REGEX =
  // eslint-disable-next-line max-len
  /(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/;

export const isPhoneNumberValid = (phone: string) => {
  if (!phone) return false;

  const PHONE_LENGTH = 12;

  const isValid = PHONE_VALIDATION_REGEX.test(phone);

  const isCorrectLength = phone.length === PHONE_LENGTH;

  return isValid && isCorrectLength;
};

const EMAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\].,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const isEmailValid = (email: string) => {
  if (!email) return;

  return EMAIL_REGEX.test(email);
};

export const uniquefyArray = <T extends { _id: string }[]>(arr: T) => {
  const uniqueArr = arr.filter(
    (item, index, self) => index === self.findIndex(t => t._id === item._id),
  );
  return uniqueArr;
};

export const uniquefyCacheData = <T extends { __ref: string }[]>(arr: T) => {
  const uniqueArr = arr.filter(
    (item, index, self) => index === self.findIndex(t => t.__ref === item.__ref),
  );
  return uniqueArr;
};

export const getSafeNumberFromInput = (value: string | number, allowCommas = true) => {
  const nb = allowCommas
    ? +value.toString().replace(/,/, ".")
    : +value.toString().replace(/,|\./g, "INVALID");

  if (isNaN(nb)) return 0;
  return nb;
};

export const isBetween = (date: Date, from: Date, to: Date, inclusivity = "()") => {
  if (!["()", "[]", "(]", "[)"].includes(inclusivity))
    throw new Error("Inclusivity parameter must be one of (), [], (], [)");

  const isBeforeEqual = inclusivity[0] === "[";
  const isAfterEqual = inclusivity[1] === "]";

  return (
    (isBeforeEqual
      ? isEqual(from, date) || isBefore(from, date)
      : isBefore(from, date)) &&
    (isAfterEqual ? isEqual(to, date) || isAfter(to, date) : isAfter(to, date))
  );
};

export const convertOriginNbToEnum = (origin: number) => {
  switch (origin) {
    case 10:
      return Origin.Phone;
    case 20:
      return Origin.Local;
    case 30:
      return Origin.Web;
    case 40:
      return Origin.Mobile;
    case 50:
      return Origin.Iframe;
    case 60:
      return Origin.Google;
    case 70:
      return Origin.BusinessTable;
    case 80:
      return Origin.HotelClient;
    case 90:
      return Origin.Email;
    case 100:
      return Origin.Facebook;
    case 110:
      return Origin.Fairtrip;
    case 120:
      return Origin.Instagram;
    case 130:
      return Origin.TheFork;
    case 140:
      return Origin.LasTable;
    case 150:
      return Origin.Marketing;
    case 160:
      return Origin.Michelin;
    case 170:
      return Origin.OpenTable;
    case 180:
      return Origin.Oral;
    case 190:
      return Origin.OuBruncher;
    case 200:
      return Origin.Restopolitain;
    case 210:
      return Origin.Sms;
    default:
      return Origin.Web;
  }
};

export const getLabelsForOrigin = (originKey: string, originComplete?: Origin) => {
  const origin = !originComplete ? Origin[originKey] : originComplete;
  switch (origin) {
    case Origin.BusinessTable:
      return "BusinessTable";
    case Origin.Email:
      return "Email";
    case Origin.Phone:
      return "Téléphone";
    case Origin.Facebook:
      return "Facebook";
    case Origin.Instagram:
      return "Instagram";
    case Origin.Google:
      return "Google";
    case Origin.Fairtrip:
      return "Fairtrip";
    case Origin.HotelClient:
      return "Client hôtel";
    case Origin.Iframe:
      return "Iframe";
    case Origin.LasTable:
      return "LasTable";
    case Origin.Local:
      return "Passage";
    case Origin.Marketing:
      return "Marketing";
    case Origin.Michelin:
      return "Michelin";
    case Origin.Mobile:
      return "Mobile";
    case Origin.OpenTable:
      return "OpenTable";
    case Origin.Oral:
      return "Oral";
    case Origin.OuBruncher:
      return "OuBruncher";
    case Origin.Restopolitain:
      return "Restopolitain";
    case Origin.Sms:
      return "SMS";
    case Origin.TheFork:
      return "LaFourchette";
    case Origin.Web:
      return "Web";

    default:
      break;
  }
  return "-";
};

export const getLabelsForEvent = (eventKey: string, eventComplete?: Event_Type) => {
  const event = !eventComplete ? Event_Type[eventKey] : eventComplete;
  switch (event) {
    case Event_Type.Anniversary:
      return "Anniversaire";
    case Event_Type.BabyShower:
      return "Baby Shower";
    case Event_Type.BachelorParty:
      return "Enterrement de vie de garçon";
    case Event_Type.BacheloretteParty:
      return "Enterrement de vie de jeune fille";
    case Event_Type.Baptism:
      return "Baptême";
    case Event_Type.Birthday:
      return "Anniversaire";
    case Event_Type.BusinessMeeting:
      return "Réunion d'affaires";
    case Event_Type.Communion:
      return "Communion";
    case Event_Type.CompanyEvent:
      return "Evénement d'entreprise";
    case Event_Type.Confirmation:
      return "Confirmation";
    case Event_Type.Diploma:
      return "Diplôme";
    case Event_Type.Family:
      return "Famille";
    case Event_Type.Friends:
      return "Amis";
    case Event_Type.Graduation:
      return "Remise de diplôme";
    case Event_Type.Funeral:
      return "Funérailles";
    case Event_Type.GroupMeal:
      return "Repas de groupe";
    case Event_Type.None:
      return "Aucun";
    case Event_Type.Other:
      return "Autre";
    case Event_Type.Retirement:
      return "Retraite";
    case Event_Type.Romantic:
      return "Romantique";
    case Event_Type.SportClub:
      return "Club de sport";
    case Event_Type.Wedding:
      return "Mariage";
    case Event_Type.Work:
      return "Travail";
    case Event_Type.ThemeParty:
      return "Soirée à thème";

    default:
      break;
  }
  return "-";
};

const removeAll = (str: string, search: string) =>
  str
    .split(search)
    .filter(s => s !== search)
    .join("");

export const formatPhoneNumber = (countryCode: string, phoneNumber: string) => {
  let number = removeAll(phoneNumber, `+${countryCode}`);

  if (number[0] === "0") {
    number = number.slice(1, number.length);
  }

  if (phoneNumber.length === 12) return phoneNumber;

  return `+${countryCode}${number}`;
};

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
