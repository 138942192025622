import type {
  BookingSettingsFragment,
  DecorationFragment,
  TableFragment,
} from "../../../graphql/generated/schema";
import Box from "../../Base/Box";
import type { MODULE_TYPE } from "../../ModuleList/common";
import AnimatedDecoration from "../AnimatedDecoration/index";
import AnimatedFloorItem from "../AnimatedFloorItem";
import BookingFloorGrid from "../BookingFloorGrid";

interface AnimatedFloorProps {
  decorations: DecorationFragment[];
  tables: TableFragment[];
  selectedTables: TableFragment[];
  showGrid?: boolean;
  selectTable: (id: string) => void;
  updateTable: (id: string, table: TableFragment) => void;
  updateDecoration: (id: string, decoration: DecorationFragment) => void;
  selectDecoration: (id: string) => void;
  disableMovement?: boolean;
  moduleType: MODULE_TYPE;
  bookingSettings?: BookingSettingsFragment;
  floorWidth: number;
  floorHeight: number;
  selectedBookingServiceId?: string;
  isSplittable?: boolean;
}

const AnimatedFloor = ({
  tables,
  decorations,
  updateTable,
  selectTable,
  selectDecoration,
  updateDecoration,
  selectedTables = [],
  showGrid = true,
  disableMovement = false,
  moduleType,
  bookingSettings,
  floorWidth,
  floorHeight,
  selectedBookingServiceId,
  isSplittable = false,
}: AnimatedFloorProps) => {
  const showClientName = bookingSettings?.bookingFloorSettings.showClientName;
  const showVIP = bookingSettings?.bookingFloorSettings.showVipIcon;
  const showTimer = bookingSettings?.bookingFloorSettings.showTimerForSeatedBookings;

  return (
    <Box flex={1}>
      {showGrid && <BookingFloorGrid floorWidth={floorWidth} floorHeight={floorHeight} />}

      {tables.map(table => {
        const IS_SELECTED = !!selectedTables.find(t => t._id === table._id);
        return (
          <Box key={table._id}>
            <AnimatedFloorItem
              {...{
                isSelected: IS_SELECTED,
                selectTable,
                table: table,
                updateTable,
                disableMovement,
                moduleType,
                showClientName,
                showVIP,
                floorHeight,
                floorWidth,
                selectedBookingServiceId,
                isSplittable,
                showTimer,
              }}
            />
          </Box>
        );
      })}

      {decorations.map(decoration => {
        return (
          <Box key={decoration._id}>
            <AnimatedDecoration
              {...{
                decoration,
                updateDecoration,
                disabledMovement: disableMovement,
                selectDecoration,
                isSplittable,
                floorHeight,
                floorWidth,
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};

// AnimatedFloor.whyDidYouRender = true;

export default AnimatedFloor;
